
<div class="tree-view-item">
    <div v-if="isObject(data)" class="tree-view-item-leaf">
        <div class="tree-view-item-node" @click.stop="toggleOpen()">
            <span
                :class="{ opened: isOpen() }"
                class="tree-view-item-key tree-view-item-key-with-chevron"
                >{{ getKey(data) }}</span
            >
            <span class="tree-view-item-hint" v-show="!isOpen() && data.children.length === 1"
                >{{ data.children.length }} property</span
            >
            <span class="tree-view-item-hint" v-show="!isOpen() && data.children.length !== 1"
                >{{ data.children.length }} properties</span
            >
        </div>
        <div v-if="!limitRenderDepth || isOpen()">
            <tree-view-item
                :key="getKey(child)"
                :max-depth="maxDepth"
                :current-depth="currentDepth + 1"
                v-show="isOpen()"
                v-for="child in data.children"
                :data="child"
                :modifiable="modifiable"
                :link="link"
                :limit-render-depth="limitRenderDepth"
                @change-data="onChangeData"
            ></tree-view-item>
        </div>
    </div>
    <div v-if="isArray(data)" class="tree-view-item-leaf">
        <div class="tree-view-item-node" @click.stop="toggleOpen()">
            <span
                :class="{ opened: isOpen() }"
                class="tree-view-item-key tree-view-item-key-with-chevron"
                >{{ getKey(data) }}</span
            >
            <span class="tree-view-item-hint" v-show="!isOpen() && data.children.length === 1"
                >{{ data.children.length }} item</span
            >
            <span class="tree-view-item-hint" v-show="!isOpen() && data.children.length !== 1"
                >{{ data.children.length }} items</span
            >
        </div>
        <div v-if="!limitRenderDepth || isOpen()">
            <tree-view-item
                :key="getKey(child)"
                :max-depth="maxDepth"
                :current-depth="currentDepth + 1"
                v-show="isOpen()"
                v-for="child in data.children"
                :data="child"
                :modifiable="modifiable"
                :link="link"
                :limit-render-depth="limitRenderDepth"
                @change-data="onChangeData"
            ></tree-view-item>
        </div>
    </div>
    <tree-view-item-value
        v-if="isValue(data)"
        class="tree-view-item-leaf"
        :key-string="getKey(data)"
        :data="data.value"
        :modifiable="modifiable"
        :link="link"
        @change-data="onChangeData"
    ></tree-view-item-value>
</div>
