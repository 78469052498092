
<div class="tree-view-wrapper">
    <tree-view-item
        class="tree-view-item-root"
        :data="parsedData"
        :max-depth="allOptions.maxDepth"
        :current-depth="0"
        :modifiable="allOptions.modifiable"
        :link="allOptions.link"
        :limit-render-depth="allOptions.limitRenderDepth"
        @change-data="onChangeData"
    ></tree-view-item>
</div>
