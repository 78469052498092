
<div class="tooltips-container">
    <tooltip
        v-for="tooltip in tooltips"
        :key="tooltip.id"
        :position="tooltip.style"
        :message="tooltip.message"
        :hidden="tooltip.hidden"
    />
</div>
