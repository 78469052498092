
<div>
    <loading
        v-if="loadingState"
        :status="loadingState.message"
        :progress="loadingState.progress * 100"
        :error="loadingState.error ? loadingState.message : null"
        :errorAction="false"
        :errorTitle="loadingState.title || 'This inst is having trouble loading.'"
        :show="loadingState && !loadingState.done"
        :version="version"
        @dismiss="dismissLoading()"
    >
    </loading>
    <div v-if="!loading">
        <slot></slot>
    </div>
</div>
