
<simple-editor v-if="!showDesktopEditor" :bot="bot" :tag="tag"></simple-editor>
<monaco-editor
    v-else
    :simId="simId"
    :bot="bot"
    :tag="tag"
    :space="space"
    :showResize="showResize"
    ref="monacoEditor"
    @onFocused="onFocused"
    @modelChanged="onModelChanged"
></monaco-editor>
