
<div>
    <md-dialog :md-active.sync="showCheckoutDialog" @md-closed="closeCheckoutDialog()">
        <checkout-form
            :channelId="simulationId"
            :productId="productId"
            :processingServer="processingServer"
            :title="title"
            :description="description"
            :requestBillingAddress="requestBillingAddress"
            :paymentRequest="paymentRequest"
            :publishableKey="publishableKey"
            @paymentSuccess="checkoutFinished"
            @paymentCanceled="checkoutCanceled"
        ></checkout-form>
    </md-dialog>
</div>
