
<div class="value-input-container">
    <textarea
        ref="textarea"
        @input="valueChanged(bot, tag, $event.target.value)"
        @focus="onFocus()"
        @blur="onBlur()"
        :value="value"
        class="value-input"
        :disabled="readOnly"
        :class="[
            {
                formula: isFormula && showFormulaWhenFocused,
                script: isScript,
            },
            tag,
            activeTheme,
        ]"
        autocapitalize="none"
        autocorrect="off"
        @keydown.enter.prevent="triggerNewTag()"
    />
    <md-button v-if="showSpace && !!space" class="space-indicator md-dense">
        {{ spaceAbbreviation }}
        <md-tooltip>{{ space }}</md-tooltip>
    </md-button>
</div>
