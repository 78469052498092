
<div class="bot-portal-container">
    <div
        ref="portalContainer"
        class="bot-portal"
        :style="extraStyle"
        v-show="hasPortal"
    >
        <pre>{{ currentBot }}</pre>
    </div>
    <div ref="otherContainer" class="other-container">
        <slot></slot>
    </div>
</div>
